import React from "react";
import { Helmet } from "react-helmet-async";

const AccessibilityPolicy = ({ staticContext = {} }) => {
  staticContext.notFound = true;

  return (
    <>
      <Helmet>
        <title>Accessibility Policy</title>
      </Helmet>
      <section className="general-content general-content--accessibility general-content--alt general-content--left">
        <div className="container">
          <div
            className="general-content__content"
            style={{ maxWidth: `${1024}px` }}
          >
            <h1 className="general-content__title">Accessibility Policy</h1>
            <div className="general-content__description">
              <h2>General</h2>
              <p>
                Access to any company’s website can be challenging for persons
                having certain disabilities. A person’s disability is often
                unique. What may work well for one person, may cause
                difficulties for another person. Our company has made efforts to
                accommodate as many of our customers and potential customers as
                is reasonable given our size, resources, and knowledge of our
                customers, and potential customer’s needs. To that end we have
                engaged the services of professionals to assist and advise us in
                these matters.
              </p>
              <h2>Accessibility on this Website</h2>
              <p>
                Our website provides several methods, features, and policies
                that can help with access to our website and/or to products or
                services provided or referred to on our website or by our
                business.
              </p>
              <p>
                There are also various aids available by third parties and which
                are provided by most browsers.{" "}
              </p>
              <p>
                If you are having difficulty with access to our website even
                after utilizing any access features within this website and/or
                any third party or browser features, we invite you to contact us
                for further assistance. Contact information is set forth below.
              </p>
              <h2>Some Specific Accessibility Features within this website.</h2>
              <p>
                This website contains a third-party plugin, also known as a
                Widget, called UserWay Website Accessibility (“UserWay”), which
                is powered by this third party’s dedicated accessibility server.
                Use of this Widget can improve website access for some users,
                particularly related to certain type of disabilities. UserWay
                has stated that its application makes efforts to follow to some
                degree the Web Content Accessibility Guidelines (WCAG 2.1). On
                our website, the UserWay widget is located in the BOTTOM-RIGHT
                CORNER of most pages.
              </p>
              <h2>Enabling the Accessibility Menu</h2>
              <p>
                The accessibility menu can be enabled by clicking the
                accessibility menu icon that appears after clicking on the
                Widget described above. After triggering the accessibility menu,
                please wait a moment for the accessibility menu to load in its
                entirety.
              </p>
              <h2>Disclaimer</h2>
              <p>
                We anticipate that from time to time, within our resources, we
                will be making modifications to parts of our website, and
                possibly modifications to accessibility of our website.
                Reasonable efforts toward improving seamless, accessible, and
                unhindered use of websites by customers and potential customers
                is generally a worthwhile goal. Moving closer to this goal will
                often depend on the knowledge the company has regarding any
                particular difficulties those using the website might encounter,
                as well as available resources and improvements with technology.{" "}
              </p>
              <p>
                Despite the efforts we may have made regarding accessibility,
                consistent with normal business practices for a company of our
                size and resources, some content, features, processes, or
                policies, may be improved, so we welcome your suggestions.
              </p>
              <h2>Third Party Applications</h2>
              <p>
                Our website may use third-party add-ons or “plug-ins” for
                certain functions, such as Google Maps, social media feeds, etc.
                These may not work, or may not work the same, for every user
                and/or every type of disability. We do not have control over the
                structure of these plugins, and are unable to modify them at all
                or to the extent that would accommodate every user of our
                website, and are not responsible for those elements which we do
                not control.
              </p>
              <h2>Video</h2>
              <p>
                Our website has or plans to have some video elements. Each video
                will either have close captioning or text equivalent located
                next to the video.
              </p>
              <h2>We are Here for You</h2>
              <p>
                If you are experiencing difficulties with any content on our
                website because of a disability, or if you require assistance
                with any part of our site because of your particular disability,
                please contact us and let us know. We will be happy to assist.
              </p>
              <p>
                The following telephone number is only for assisting you if you
                require assistance with any part of our site because of your
                particular disability or because you are having difficulty in
                accessing or navigating any part of our website because of your
                particular disability. This number is not to be used for sales
                or product information, but is to be used for help using,
                understanding, and/or navigating our website and information set
                forth on the website. Other pages and/or links within our
                website may contain other telephone numbers to assist with
                sales, service, product information, accounts, or other aspects
                of our business. However, as noted above, if you have a
                disability that has caused or may likely cause you difficulty in
                accessing or navigating any part of our website because of your
                particular disability, you can call us at:
              </p>
              <p>
                <a href="tel:4156832904">(415) 683-2904</a>
              </p>
              <p>
                This telephone number for assisting persons with disabilities,
                including but not limited to blind and visually impaired
                persons, is available 24 hours / 7 days a week.{" "}
              </p>
              <p>
                You can also send an email, explaining in as much detail as
                possible, what type of difficulty you had, or may have had, or
                believe others may have. These types of emails should be sent
                to: <a href="mailto:vip@milacares.com">vip@milacares.com</a> for
                forwarding to us. Note: if the email is sent to a third party
                website or mail server for forwarding to us, the third party
                website or mail servers does not itself read or respond to these
                emails, but will only forward such email to us without prior
                review.
              </p>
              <p>
                In contacting us by telephone or email, your privacy will be
                strictly protected. This means that you will not be required to
                provide any personal information, including but not limited to
                your:
              </p>
              <p>
                Name, address, telephone number, the specific type of disability
                you may have, information about goods, products, or services you
                might be interested in, email address (unless you include it in
                an email you send to us or to our representatives).
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

AccessibilityPolicy.defaultProps = {
  staticContext: {},
};

export default {
  component: AccessibilityPolicy,
};
